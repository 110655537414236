var container;
var camera, scene, renderer, material, logoobj, light, controls, clock, sizeby, diviceRate,obj3d, axis;
var mouseX = 0, mouseY = 0;
var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;

var webgl = {
	obj: "",
	mtl: "",
	rotateX: 0,
	rotateY: 0,
	sizeby: 0,
	ccShowPos: 0,
	init: function(){
		api.init();
		designSlide.init();
		/*
		webgl.obj = $("#design_body").attr("data-obj");
		webgl.mtl = $("#design_body").attr("data-mtl");
		
		//GL
		container 	= document.getElementById( 'cvs' );
		camera 		= new THREE.PerspectiveCamera( 45, container.width / container.height, 1, 5000 );
		if(isSP){
			camera.position.z = 250;
		}else{
			camera.position.z = 400;
		}
		var retinaSwitch = window.devicePixelRatio;
		if(retinaSwitch == 1) {
			webgl.sizeby = 1;
		} else if(retinaSwitch >= 2) {
			webgl.sizeby = 2;
		}
		clock = new THREE.Clock();
		// scene
		scene = new THREE.Scene();
		var ambient = new THREE.AmbientLight( 0xf5f5f5);
		scene.add( ambient );

		var dlight = new THREE.DirectionalLight( 0xffffff, 0.7);
		dlight.shadow.mapSize.width 	= 512*sizeby;
		dlight.shadow.mapSize.height 	= 512*sizeby;
		dlight.position.set( .5, .5, 0 ).normalize();
		scene.add( dlight );
		renderer = new THREE.WebGLRenderer({ alpha: true });
		renderer.shadowMap.enabled = true;
		renderer.setClearColor( 0x333333, 1.0 );
		container.appendChild( renderer.domElement );

		webgl.resize();

		var manager = new THREE.LoadingManager();
		manager.onProgress = function ( item, loaded, total ) {
			//console.log( item, loaded, total );
		};
		var onProgress = function ( xhr ) {
			if ( xhr.lengthComputable ) {
				var percentComplete = xhr.loaded / xhr.total * 100;
				//console.log( Math.round(percentComplete, 2) + '% downloaded' );
			}
		};
		var onError = function ( xhr ) {};
		//orbit contarol
		controls = new THREE.OrbitControls( camera, renderer.domElement );
		controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
		controls.dampingFactor = 0.25;
		controls.rotateSpeed = 0.25;
		controls.enableZoom = false;
		controls.target.set(0,5,0);

		controls.screenSpacePanning = false;

		axis = new THREE.AxisHelper(2000);
		axis.position.set(0,-1,0); 
		scene.add(axis);
		*/
		//controls.minDistance = 100;
		//controls.maxDistance = 500;

		//controls.maxPolarAngle = Math.PI / 2

		/*
		obj3d = new THREE.Object3D();
		var assetPath 	= "/wp-content/themes/archable_themes/assets/images/obj/";
		
		//load objects
		var mtlLoader 	= new THREE.MTLLoader();
		var objLoader 	= new THREE.OBJLoader();
		//mtlLoader.setPath(assetPath);
		//objLoader.setPath(assetPath);
	
		loadObj();
		*/
		webgl.nav();
		function loadObj(){
			var mtlfile = webgl.mtl;
			var objfile = webgl.obj;
			mtlLoader.load(mtlfile, function( materials ){
				materials.preload();
				objLoader.setMaterials(materials);

				objLoader.load( objfile, function ( object ) {
					var _o = object;
					
					var scale = .2;
					_o.scale.set(scale,scale,scale);
					let _w = $("#slideshow_main").width();
					let _h = $("#slideshow_main").height();
					//_o.scale.set(_w/2, _h/2, _w);
					_o.position.x 	= -50;
					_o.position.y 	= -95;
					_o.castShadow 	= true;
					
					obj3d.add(_o);
					scene.add(obj3d);
					console.log(obj3d)
					//$("#loading .current").html(count);
					webgl.resize();
					
					console.log("obj読み込み完了")
					webgl.animate();
				}, onProgress, onError);
			});
		}
	},
	resize: function(){
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var _w 	= $("#slideshow_main").width();
		var _h 	= $("#slideshow_main").height();
		
		renderer.setPixelRatio( window.devicePixelRatio );
		if(window.innerWidth < 864){
			diviceRate = 1;
		}else{
			diviceRate = 2;
		}
		/*
		$("#cvs canvas").css({
			"width": _w + "px",
			"height": _h + "px"
		});
		$("#cvs canvas").attr({
			"width" : _w*diviceRate + "px",
			"height": _h*diviceRate + "px"
		});
		renderer.setSize( _w, _h);
		camera.aspect = _w / _h;
		camera.updateProjectionMatrix();
		*/
	},
	nav: function(){
		$("#nav_tab ul li a").on("click", function(){
			var idx = $("#nav_tab ul li a").index(this);
			$("#nav_tab ul li a").removeClass("active");
			$(this).addClass("active");
			$(".tab").removeClass("active");
			$(".tab").eq(idx).addClass("active");
		});
		//
		var $addimg = $("#add_img");
		$("#btn_upload_img").mouseenter(function(){
			if(!$addimg.hasClass("active")){
				$addimg.addClass("active");
				setTimeout(function(){
					$addimg.addClass("show");
				},50);
			}
		}).mouseleave(function(){
			if($addimg.hasClass("active")){
				$addimg.removeClass("show");
				setTimeout(function(){
					$addimg.removeClass("active");
				},200);
			}
		});
		//
		let $cc = $("#cc_hover");
		$(".icons_cc").mouseenter(function(){
			var pos = $(".icons_cc").position().left;
			var posY = $(".icons_cc").position().top;
			webgl.ccShowPos = $(document).scrollTop();
			$cc.css({
				"top": (posY - 100) + "px",
				"left": pos + "px"
			});
			if(!$cc.hasClass("active")){
				$cc.addClass("active");
				setTimeout(function(){
					$cc.addClass("show");
				},50);
			}
		});
		$cc.mouseleave(function(){
			if($cc.hasClass("active")){
				$cc.removeClass("show");
				setTimeout(function(){
					$cc.removeClass("active");
				},200);
			}
		});
		//SP
		$(".icons_cc").off("click").on("click", function(){
			var pos = $(".icon_cc").position().left;
			webgl.ccShowPos = $(document).scrollTop();
			$cc.css("left", pos + "px");
			if(!$cc.hasClass("active")){
				$cc.addClass("active");
				setTimeout(function(){
					$cc.addClass("show");
				},50);
			}else{
				$cc.removeClass("show");
				setTimeout(function(){
					$cc.removeClass("active");
				},200);
			}
		});
		$("#btn_dl").off().on("click", function(){
			if(!$html.classList.contains("dl_modal")){
				$html.classList.add("dl_modal");
				setTimeout(function(){
					$html.classList.add("show_dl_modal");
				},50);
			}
		});
		$("#btn_close_modal").off().on("click", function(){
			if($html.classList.contains("dl_modal")){
				$html.classList.remove("show_dl_modal");
				setTimeout(function(){
					$html.classList.remove("dl_modal");
				}, 600);
			}
		});
		$("#bg_modal").off().on("click", function(){
			if($html.classList.contains("dl_modal")){
				$html.classList.remove("show_dl_modal");
				setTimeout(function(){
					$html.classList.remove("dl_modal");
				}, 600);
			}
		});
	},
	animate: function(){
		requestAnimationFrame( webgl.animate );
		controls.update();
		webgl.update();
	},
	render: function(){
		var time = Date.now() * 0.00025;

		camera.lookAt( scene.position );
		renderer.render( scene, camera );
	},
	update: function(){
		var time = Date.now() * 0.00025;
		var thisEarth;
		//obj3d.rotation.y += 0.01;
		//console.log("update")
		webgl.render();
	}
}


var designSlide = {
	current: 0,
	max: 0,
	init: function(){
		designSlide.max = $("#slideshow_main li").length;
		//console.log(designSlide.current, " / ", designSlide.max);
		designSlide.nav();
		designSlide.change();
	},
	nav: function(){
		$("#slideshow_nav li a").off().on("click", function(){
			designSlide.current = $("#slideshow_nav li a").index(this);
			designSlide.change();
			//console.log(designSlide.current);
		});
		var $wrap = $("#slideshow_main");
		if(isSP){
			const limit = 60;
			var position,positionY,cx,touching,cy,
				angle;
			$wrap.off().on({
				'touchstart': function(e) {
					cx 			= 0;
					position 	= getPosition(e);
				},
				'touchmove': function(e) {
					//e.preventDefault();
					var touchX 	= event.touches[0].clientX;
					cx 	= position - getPosition(event);
				},
				'touchend': function(e) {
					if(cx < -limit){
						designSlide.dec();
					}else if(cx > limit){
						designSlide.inc();
					}else{
					}
					cx = 0;
				},
				'touchcancel': function(e){
					cx = 0;
					resetPos(num);
				}
			});
			function resetPos(){
				cx = 0;
			}
			function getPosition(event){
				return event.touches[0].pageX;
			}
		}
	},
	inc: function(){
		designSlide.current++;
		if(designSlide.current >= designSlide.max-1){
			designSlide.current = 0;
		}
		designSlide.change();
	},
	dec: function(){
		designSlide.current--;
		if(designSlide.current < 0){
			designSlide.current = designSlide.max-1;
		}
		designSlide.change();
	},
	change: function(){
		var len = $(".movie").length;
		if(movie.hasVideo){
			if(players[0].pauseVideo){
				for(var i = 0; i < len; i++){
					players[i].pauseVideo();
				}
			}
		}
		
		$("#slideshow_main li").removeClass("active");
		$("#slideshow_main li").eq(designSlide.current).addClass("active");
		$("#slideshow_nav li a").removeClass("active");
		$("#slideshow_nav li").eq(designSlide.current).find("a").addClass("active");
	}
}