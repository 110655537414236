var ytFlag 	= 0;
var YT,ytPlayer,moveTimer, movieTimer;
function onYouTubeIframeAPIReady() {
	movie.ready = true;
	if(isPage === "archive"){
		movie.init();
	}
}
var ids = [];
var players = [];
var movie = {
	hasVideo 	: false,
	ready 		: false,
	canPlay 	: false,
	movieID 	: "",
	isPlaying	: false,
	state: 0,
	player: "",
	init: function(){
		var isMute = 1;
		var count = 0;
		var len = $(".movie").length;
		if(len > 0){
			movie.hasVideo = true;
			for(var i = 0; i < len; i++){
				ids.push($(".movie").eq(i).attr("id"));
			}
			console.log(ids, len)
			for(var j = 0; j < len; j++){
				getMovie(j);
			}
			designSlide.change();
			function getMovie(j){
				var movieID = $("#" + ids[j]).attr("data-id");
				players[j] = new YT.Player(ids[j], {
					videoId 	: movieID,
					playerVars 	: {
						autohide	: 1,
						controls	: 1,
						frameborder : 0,
						rel			: 0,
						start		: 0,
						showinfo 	: 0,
						disablekb	: 1,
						wmode		: "transparent",
						autoplay	: 0,
						loop 		: 1,
						mute 		: 1,
						playsinline	: 1,
						enablejsapi	: 1,
						iv_load_policy: 3
					},
					events : {
						onReady: function(e){
							players[j] = e.target;
						},
						onStateChange: function(e){
							//console.log(movie.state)
							
							
						},
						onError: function(e){
							var errorstatus = e.data;
						}
					}
				});
			}
		}else{
			movie.hasVideo = false;
			designSlide.change();
		}
	}
}