var slideTimer;
var slide = {
	current	: 0,
	max 	: 0,
	duration: 6000,
	flag	: true,
	init: function(){
		//return false
		slide.current = 0;
		slide.max = $("#slideshow ul li").length;
		slide.resize();

		var navHTML = "";
		for(var i = 0; i < slide.max; i++){
			if(i === 0){
				navHTML += '<li><a class="active" href="javascript:void(0);">' + i + '</a></li>';
			}else{
				navHTML += '<li><a href="javascript:void(0);">' + i + '</a></li>';
			}
		}
		$(".pager ul").html(navHTML);
		slide.nav();
		//console.log(slide.current, slide.max);
		slideTimer = setInterval(function(){
			slide.inc();
		}, slide.duration);
	},
	resize: function(){
		if(isSmallScreen){
			var gridW = 48;
			$(".pager").css({
				"top" : ""
			});
		}else{
			var gridW = 48;
			var _h = $("#slideshow").height();
			var t = slide.max*-48/2;
			$(".pager").css({
				"top" : "50%",
				"margin-top": t + "px"
			});
		}
		
	},
	nav: function(){
		$(".pager ul li a").off().on("click", function(){
			if(slide.flag){
				slide.flag = false;
				slide.current = $(".pager ul li a").index(this);
				slide.change();
			}
		});
		$("#block_slide a.btn_prev").off().on("click", function(){
			slide.dec();
		});
		$("#block_slide a.btn_next").off().on("click", function(){
			slide.inc();
		});
	},
	inc: function(){
		if(slide.flag){
			slide.falg = false;
			slide.current++;
			if(slide.current >= slide.max){
				slide.current = 0;
			}
			slide.change();
		}
	},
	dec: function(){
		if(slide.flag){
			slide.current--;
			if(slide.current < 0){
				slide.current = slide.max-1;
			}
			slide.change();
		}
	},
	change: function(){
		slide.falg = false;
		clearInterval(slideTimer);
		$(".pager ul li a").removeClass();
		$(".pager ul li").eq(slide.current).find("a").addClass("active");

		$("#slideshow ul").addClass("disable");
		var duration = 500;
		setTimeout(function(){
			$("#slideshow ul li").removeClass("active");
			$("#slideshow ul").addClass("hide");
			
			setTimeout(function(){
				$("#slideshow ul li").eq(slide.current).addClass("active");
				$("#slideshow ul").addClass("change");
				$("#slideshow ul").removeClass("hide");

				setTimeout(function(){
					$("#slideshow ul").removeClass("disable");
					setTimeout(function(){
						$("#slideshow ul").removeClass("change");
						slide.flag = true;
						clearInterval(slideTimer);
						slideTimer = setInterval(function(){
							slide.inc();
						}, slide.duration);
					},duration);

				},duration);
			},duration);
		},duration);
	}
}