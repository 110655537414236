var api = {
	id: "",
	formData: "",
	path : "https://api.arch-able.jp",
	init: function(){
		api.id = $("article").attr("data-id");
		//console.log("page ID", api.id);
		//詳細ページ用
		api.designDetail();
		api.nav();
	},
	nav: function(){
		// SUBMIT COMMENT ---------------------------------------------------------
		$("#submit a").off().on("click", function(){
			var cmt = $("#wrap_comment textarea").val();
			api.postComment(cmt);
		});
		// IMAGE REPORT ---------------------------------------------------------
		$('#img_post').change(function() {
			var formdata = $('#img_post')[0].files[0];
			var name = formdata.name;
			if(!$(".img_info").hasClass("active")){
				$(".img_info").addClass("active");
				$('#img_name').html(name);
				$("#delete_image").off().on("click", function(){
					$(".img_info").removeClass("active");
					$('#img_name').html();
					$("#img_post").val("");
				});
			}
			api.formData = formdata;
		});
		// SUBMIT REPORT ---------------------------------------------------------
		$("#btn_report_submit").off().on("click", function(){
			api.postReport();
		});
		// COMMENT MORE ---------------------------------------------------------
		$("#list_comment .more a").off().on("click", function(){
			let lastID = $("#list_comment li:last-child").attr("data-num");
			lastID = parseInt(lastID);
			api.getComment(lastID);
		});
		// DOWNLOAD ---------------------------------------------------------
		$("a.btn_dl").off().on("click", function(){
			api.postDownload();
		});
		// LIKE ---------------------------------------------------------
		$("a.btn_like").off().on("click", function(){
			if($(this).hasClass("active")){
				//Like取り消し?
			}else{
				api.postLike();
			}
		});
		/*
		$("body").on("keyup", function(e){
			console.log(e.keyCode)
			var code = e.keyCode;
			if(code === 68){
				localStorage.removeItem("arch-able_likes");
			}
		});
		*/
	},
	designAll: function(){
		//GET
		var url = api.path + "/designs.json";
		var param = {};
		load.getJson(url, param).done(function(json){
			let param 		= json.designs[0];
			let pvCount 	= param.pv_count;
			let likeCount 	= param.like_count;
			let dlCount 	= param.download_count;
			console.log(json);
		});
	},
	designDetail: function(){
		//GET
		var url = api.path + "/designs/" + api.id + ".json";
		var param = {};
		load.getJson(url, param).done(function(json){
			$(".num_view").html(json.pv_count);
			$(".num_like").html(json.like_count);
			$(".num_dl").html(json.download_count);
			api.makeReport(json.reports);
			api.makeComments(json);
		});
	},
	getComment: function(id){
		var url = api.path + "/designs/" + api.id + "/comments.json";
		var param = {
			max_id: id
		};
		load.getJson(url, param).done(function(json){
			console.log(json)
			api.makeComments(json);
		});
	},
	postLike: function(id){
		//POST
		var url = api.path + "/designs/" + api.id + "/likes.json";
		var param = {};
		api.saveToStorage();
		load.postJson(url, param).done(function(json){
			//$(".num_view").html(json.pv_count);
			$(".num_like").html(json.like_count);
			$(".num_like").addClass("active");
			$("a.btn_like").addClass("active");
			//$(".num_dl").html(json.download_count);
		});
	},
	postDownload: function(){
		var url = api.path + "/designs/" + api.id + "/downloads.json";
		var param = {};
		load.postJson(url, param).done(function(json){
			//$(".num_view").html(json.pv_count);
			//$(".num_like").html(json.like_count);
			$(".num_dl").html(json.download_count);
		});
	},
	postComment: function(comment){
		var url = api.path + "/designs/" + api.id + "/comments.json";
		if(comment != ""){
			var param = {
				"content": comment
			};
			load.postJson(url, param).done(function(json){
				$("#block_comment").addClass("posted");
			});
		}
	},
	postReport: function(){
		var url = api.path + "/designs/" + api.id + "/reports.json";
		//console.log(api.formData);
		var text = $("#textarea_report").val();
		$('input[name="content"]').val(text);
		if(text != ""){
			var form = $("#form_report").get()[0];
			var formData = new FormData( form );
			var param = formData;
			load.postForm(url, param).done(function(json){
				$("#block_post_report").addClass("posted");
			});
		}
	},
	makeReport: function(data){
		var reports = data;
		var HTML = '';
		var d = new Date();
		var today = Math.floor( d.getTime() /1000 );

		for(var i = 0; i < reports.length; i++){
			var r = reports[i];
			var diffTime = calcDiff(today, r.created_at);
			
			HTML 	+= '<li>'
					+ '<div class="ttl_report">'
					+ '<h4>' + r.number + '</h4>'
					+ '<p class="date">' + diffTime + '</p>'
					+ '</div>'
					+ '<div class="content_report">'
					+ '<figure style="background-image: url(' + r.image_url + ')"></figure>'
					+ '<p>' + r.content + '</p>'
					+ '</div>'
					+ '</li>';
		}
		$("ul.list_report").html(HTML);
	},
	makeComments: function(data){
		var comments 	= data.comments;
		if(comments.length > 0){
			if(data.comment_min_id === comments[comments.length-1].id){
				$("#list_comment .more").remove();
			}
			var HTML 		= '';
			var d 			= new Date();
			var today = Math.floor( d.getTime() /1000 );

			//today();
			for(var i = 0; i < comments.length; i++){
				var c = comments[i];
				//console.log(c)
				var diffTime = calcDiff(today, c.created_at);
				HTML 	+= '<li data-num="' + c.id + '">'
						+ '<div class="info_comment">'
						+ '<p class="n">' + c.number + '</p>'
						+ '<p class="time">' + diffTime + '</p>'
						+ '</div>'
						+ '<p class="txt">' + c.content + '</p>'
						+ '</li>';
			}
			$("#list_comment ul").append(HTML);
		}else{
			$("#list_comment .more").remove();
		}
	},
	saveToStorage: function(){
		var arry = localStorage.getItem('arch-able_likes');
		if(!arry){
			localStorage.setItem("arch-able_likes", api.id);
		}else{
			var likes = arry.toString();
			likes += "," + api.id;
			localStorage.setItem("arch-able_likes", likes);
		}
	},
	checkList: function(){
		var url = api.path + "/designs.json";
		var param = {};
		load.getJson(url, param).done(function(json){
			console.log(json)
			var design = json.designs;
			for(var i = 0; i < design.length; i++){
				var d = design[i];
				var id = d.id;
				var $thisBlock = $("#design_" + id);
				$thisBlock.find(".info_design li").eq(0).find("span").html(d.pv_count);
				$thisBlock.find(".info_design li").eq(1).find("span").html(d.like_count);
				$thisBlock.find(".info_design li").eq(2).find("span").html(d.download_count);
			}
		});
	}
}
function calcDiff(today, created_at){
	var diffTime = "";
	var diff = today - created_at;
	var dM = Math.floor(diff/60);
	var dH = Math.floor(dM/60);
	var dD = Math.floor(dH/24);
	//console.log(diff, dM, dH, dD)
	if(dD < 1){
		if(dH < 1){
			diffTime = dM + "minutes ago";
		}else{
			diffTime = dH + "hour ago";
		}
	}else{
		diffTime = dD + "days ago";
	}
	return diffTime;
}