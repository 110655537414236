var isPage = "";
var Block = {
	top: 0,
	show: 0,
	id: 0
}
var blocks = [];
var Animate = {
	top: 0,
	show: 0,
	id: 0
}
var animates = [];
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		common.checkLikes();
		if(isPage === "index"){
			setTimeout(function(){
				slide.init();
				common.scrl();
				api.checkList();
				$("body").removeClass("disable");
				setTimeout(function(){
					common.resize();
					common.checkHash();
				},100);
			},600);
		}else if(isPage === "archive"){
			webgl.init();
			common.scrl();
		}else if(isPage === "archvie_list"){
			common.scrl();
		}
		common.resize();

		setTimeout(function(){
			common.resize();
		},300);
		common.nav();
		common.checkURL();
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
		$(document).off().on("touchmove scroll", function(){
			common.scrl();
		});
	},
	nav: ()=>{
		var $body = $("body");
		$("#btn_menu").off().on("click", function(){
			if($body.hasClass("nav")){
				$body.removeClass("nav");
			}else{
				$body.addClass("nav");
			}
		});
		$(".page_nav li a").not(".nav_news").off().on("click", function(){
			var idx = $(".page_nav li a").index(this) + 1;
			var posY = blocks[idx].top;
			common.scrollToPosition(posY);
			$body.removeClass("nav");
		});
		$(".pagetop a").off().on("click", function(){
			common.scrollToPosition(0);
		});
		$("a.mail").off().on("click", function(){
			location.href = 'mailto:info@arch-able.jp?subject=ARCHABLEへのお問い合わせ'
		});
		$(".nav li a").not(".nav_news").off().on("click", function(){
			var idx = $(".nav li a").index(this);
			if(isPage === "index"){
				if(idx === 0){
					var posY = 0;
				}else{
					var posY = blocks[idx].top;
				}
				common.scrollToPosition(posY);
			}else{

			}
		});
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436,left=' + (screen.width-626)/2 + ",top=" + (screen.height-436)/2);
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436,left=' + (screen.width-626)/2 + ",top=" + (screen.height-436)/2);
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
		//about
		$(".block_about h4 a").off().on("click", function(){
			var idx = $(".block_about h4 a").index(this);
			var $this = $(".block_about").eq(idx);
			if(!$this.hasClass("active")){
				$this.addClass("active");
			}else{
				$this.removeClass("active");
			}
			$this.find(".block_toggle").animate({
				height: "toggle", opacity: "toggle"
			},400);
			common.resize();
			setTimeout(function(){
				common.resize();
			},400);
		});
		$("a.btn_privacy").off().on("click", function(){
			let path = "?page=privacy";
			window.history.pushState(null, null, path);
			common.checkURL();
		});
		$("a#close_privacy").off().on("click", function(){
			let path = "/";
			window.history.pushState(null, null, path);
			common.checkURL();
		});
		$("a.btn_terms").off().on("click", function(){
			let path = "?page=terms";
			window.history.pushState(null, null, path);
			common.checkURL();
		});
		$("a#close_terms").off().on("click", function(){
			let path = "/";
			window.history.pushState(null, null, path);
			common.checkURL();
		});
		//blog
		var $thisShare;
		$(".share_post li a").off().on("click", function(){
			var parent = $(".share_post").index($(this).parent().parent());

			$thisShare = $(this);
			var idx = $(".share_post").eq(parent).find("li a").index(this);
			sharePost(idx);
		});
		function sharePost(num){
			var url = $thisShare.attr("data-url");
			if(num === 0){
				var txt = $thisShare.attr("data-text");
				//Twitter
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436,left=' + (screen.width-626)/2 + ",top=" + (screen.height-436)/2);
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436,left=' + (screen.width-626)/2 + ",top=" + (screen.height-436)/2);
			}
		}
		//blog
		var $thisArchiveShare;
		$("#archive #btns li a.share").off().on("click", function(){
			let idx = parseInt( $(this).attr("data-index") );
			$thisArchiveShare = $(this);
			shareArchive(idx);
		});
		function shareArchive(num){
			var url = location.href;
			//console.log(url, num)
			if(num === 0){
				var txt = $thisArchiveShare.attr("data-text");
				//Twitter
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436,left=' + (screen.width-626)/2 + ",top=" + (screen.height-436)/2);
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436,left=' + (screen.width-626)/2 + ",top=" + (screen.height-436)/2);
			}
		}
	},
	checkURL: function(){
		var $body 	= $("body");
		var prop 	= location.pathname;
		var arg 	= new Object;
		var pair 	= location.search.substring(1).split('&');
		var isModal	= false;

		for(var i = 0; pair[i]; i++) {
			var kv = pair[i].split('=');
			arg[kv[0]] = kv[1];
			if(kv[0] === "page"){
				if(kv[1] === "privacy"){
					isModal = true;
					common.openPrivacy();
				}
				if(kv[1] === "terms"){
					isModal = true;
					common.openTerms();
				}
			}
		}
		if(!isModal){
			common.close();
		}
	},
	openPrivacy: function(){
		var $body = $("body");
		if(!$body.hasClass("show_modal")){
			$body.addClass("m");
			setTimeout(function(){
				$body.addClass("show_modal");
				common.nav();
			},50);
		}
		if($body.hasClass("nav")){
			$body.removeClass("nav");
		}
	},
	openTerms: function(){
		var $body = $("body");
		if(!$body.hasClass("show_modal")){
			$body.addClass("t");
			setTimeout(function(){
				$body.addClass("show_modal");
				common.nav();
			},50);
		}
		if($body.hasClass("nav")){
			$body.removeClass("nav");
		}
	},
	close: function(){
		var $body = $("body");
		if($body.hasClass("m")){
			$body.removeClass("show_modal");
			setTimeout(function(){
				$body.removeClass("m");
			},600);
		}
		if($body.hasClass("t")){
			$body.removeClass("show_modal");
			setTimeout(function(){
				$body.removeClass("t");
			},600);
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		if(isPage === "index"){
			slide.resize();
		}
		common.checkBlock();
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
				common.checkBlock();
			}
		},300);
	},
	checkBlock: function(){
		animates = [];
		blocks = [];
		$(".block").each(function(i){
			var _b = object(Block);
			var $this = $(this);
			if(i != 0){
				if($this.find(".ttl_h3").length > 0){
					_b.top 	= Math.floor($this.find(".ttl_h3").offset().top - 48 - $("header").height());
					_b.show = Math.floor(_b.top + $(window).height()/3);
					_b.id 	= $this.attr("id");
				}else{
					_b.top 	= Math.floor($this.find(".block_partner").offset().top - 48 - $("header").height());
					_b.show = Math.floor(_b.top + $(window).height()/3);
					_b.id 	= $this.attr("id");
				}
			}else{
				_b.top 	= 0;
				_b.show = 0;
				_b.id 	= $this.attr("id");
			}
			blocks.push(_b);
		});
		$(".a").each(function(i){
			var _a = object(Block);
			_a.top 	= Math.floor($(this).offset().top);
			_a.show = Math.floor(_a.top + $(window).height()/3);
			_a.id 	= $(this).attr("id");
			animates.push(_a);
		});
		//console.log(animates)
	},
	scrollToPosition: (posY) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height(),
			alen = animates.length,
			blen = blocks.length,
			$body = $("body"),
			fh 	= $("footer").height();
		
		if(btmD >= $(document).height() - fh){
			if(!$body.hasClass("fix")){
				$body.addClass("fix");
			}
		}else{
			if($body.hasClass("fix")){
				$body.removeClass("fix");
			}
		}

		var winH = $(window).height();
		if(topD >= winH){
			if(!$body.hasClass("show_pagetop")){
				$body.addClass("show_pagetop")
			}
		}else{
			if($body.hasClass("show_pagetop")){
				$body.removeClass("show_pagetop")
			}
		}
		if(isPage === "index" || isPage === "archive_list"){
			for(var b = 0; b < blen; b++){
				if(b === blen - 1){
					if(topD >= blocks[b].top){
						activeBlock(b)
					}
				}else{
					if(topD >= blocks[b].top){
						activeBlock(b)
					}
				}
			}
			for(var a = 0; a < alen; a++){
				if(a === alen - 1){
					if(btmD >= animates[a].show){
						activeAnimate(a)
					}
				}else{
					if(btmD >= animates[a].show){
						activeAnimate(a)
					}
				}
			}
		}
		if(isPage === "archive"){
			if(webgl.ccShowPos != topD){
				let $cc = $("#cc_hover");
				if($cc.hasClass("active")){
					$cc.removeClass("show");
					setTimeout(function(){
						$cc.removeClass("active");
					},200);
				}
			}
		}
		
		function activeBlock(b){
			var $block = $(".block").eq(b);
			if(!$block.hasClass("active")){
				$(".block").removeClass("active");
				$block.addClass("active");
				$(".nav ul li a").removeClass();
				$(".nav ul li").eq(b).find("a").addClass("active");
			}
		}
		
		if(btmD >= $(document).height()-100){
			activeAnimate(animates.length-1);
		}
		function activeAnimate(a){
			var $block = $(".a").eq(a);
			if(!$block.hasClass("animate")){
				$block.addClass("animate");
				setTimeout(function(){
					$block.addClass("show");
					setTimeout(function(){
						$block.addClass("animated");
					},400);
				},450);
				if($block.hasClass("about0")){
					setTimeout(function(){
						$block.addClass("active");
						$block.find(".block_toggle").animate({
							height: "toggle", opacity: "toggle"
						},400);
						common.resize();
						setTimeout(function(){
							common.resize();
						},400);
					},600);
				}
				/*
				if($block.hasClass("ttl_h3") || $block.hasClass("p")){
					
				}else{
					$block.addClass("animate");
					setTimeout(function(){
						$block.addClass("animated");
					},1000);
				}
				*/
			}
		}
	},
	checkHash: function(){
		var hash 	= location.hash;
		var hashAry = hash.split("/");
		var hashLen = hashAry.length;
		var lastHash = hashAry[hashLen - 1];
		if(hashLen > 1 && lastHash != ""){
			var $block = $(".a.about0");
			common.resize();
			setTimeout(function(){
				common.resize();
				var id = "#" + lastHash;
				var posY = $(id).offset().top - $("header").height() - 48;
				common.scrollToPosition(posY);
				location.hash = "/";
			},100);
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	checkLikes: function(){
		var likeArry = localStorage.getItem('arch-able_likes');
		if(!likeArry){

		}else{
			var ary = likeArry.split(",");
			console.log(ary)
			$("[data-likeid]").each(function(){
				var $this = $(this);
				var likeID = $(this).attr("data-likeid");
				for(var i = 0; i < ary.length; i++){
					if(likeID === ary[i]){
						$this.addClass("active");
					}
				}
			});
			
			console.log(ary)
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});